import * as React from 'react';
import { Field, Icon, PaginatedTable, Breadcrumbs, Button, Modal } from '@teamsnap/teamsnap-ui';
import { useToast } from '@teamsnap/snap-ui';

import { Link } from 'react-router-dom';
import { Placement } from '@teamsnap/teamsnap-ui/dist/js/types/placement';
import { orderBy } from 'lodash';

// Local Imports
import './hockey_canada.scss';
import { useAppDispatch } from 'state/hooks';
import { useHockeyCanadaStateSelector, loadExternalConnections, deleteExternalConnection } from 'state/hockeyCanada/hockeyCanadaSlice';
import { NewConnectionForm } from './NewConnectionForm';
import { NgbExternalConnectionResponseDto } from 'core/api';

const columns = [
  { label: 'MHA Name', name: 'mhaName', isSortable: true, mods: 'u-size2of24' },
  { label: 'MHA External ID', name: 'mhaExternalId', isSortable: true, mods: 'u-size2of24' },
  { label: 'HCR3 Org ID', name: 'externalId', isSortable: true, mods: 'u-size6of24' },
  { label: 'TSB Org ID', name: 'orgId', isSortable: true, mods: 'u-size6of24' },
  { label: 'Persistent UUID', name: 'divisionUuid', isSortable: true, mods: 'u-size6of24' },
  { label: 'Actions', name: 'actions', isSortable: false, mods: 'u-size2of24' },
];


const sortColumn = (items: NgbExternalConnectionResponseDto[], sortBy: string, sortAsc: boolean) => {
  const sortField = (sortBy === 'orgId' || sortBy === 'divisionUuid') ? 'scopeId' : sortBy;
  return orderBy(items, [sortField], [sortAsc ? 'asc' : 'desc']);
};

const itemsPerPage = 100;

export const HockeyCanada = () => {
  const { toast } = useToast();

  const [searchValue, setSearchValue] = React.useState<string>();
  const [filteredData, setFilteredData] = React.useState<NgbExternalConnectionResponseDto[] | null>(null);
  const [selectedConnection, setSelectedConnection] = React.useState<NgbExternalConnectionResponseDto | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleLoad = () => dispatch(loadExternalConnections());

  const handleDelete = (id: string) => dispatch(deleteExternalConnection(id));

  const handleSearchValue = (e: { target: { value: string } }) => {
    setSearchValue(e.target.value);
  };


  const filterData = (search: string | undefined, data: NgbExternalConnectionResponseDto[]) => {
    if (!search) return data;

    if (!search.trim()) {
      return data;
    }

    const lowercasedSearch = search.toLowerCase();

    return data.filter((item) =>
      Object.values(item)
        .join(' ')
        .toLowerCase()
        .includes(lowercasedSearch)
    );
  };

  const onDelete = async () => {
    if (selectedConnection) {
      const response = await handleDelete(selectedConnection.id);

      const { error, errors } = response.payload as {
        error: boolean;
        errors: string[];
        data: { connectionId: string };
      }

      if (error || errors.length > 0) {
        toast({ variant: 'negative', title: 'Failed to delete connection' });
      } else {
        toast({ variant: 'success', title: 'Connection deleted' });
      }
    }

    setIsDeleteModalOpen(false);
  };

  const openDeleteModal = (connection: NgbExternalConnectionResponseDto) => {
    setSelectedConnection(connection);
    setIsDeleteModalOpen(true);
  };

  const closeModal = () => {
    setSelectedConnection(null);
    setIsDeleteModalOpen(false);
  };

  const displayActions = (externalConnection: NgbExternalConnectionResponseDto) => (
    <Button onClick={() => openDeleteModal(externalConnection)} type="danger">
      Delete
    </Button>
  );

  React.useEffect(() => {
    handleLoad();
  }, []);

  const { data, processing } = useHockeyCanadaStateSelector();

  const loadData = async ({
    page,
    itemsPerPage,
    sortBy,
    sortAsc,
  }: {
    page: number;
    itemsPerPage: number;
    sortBy: string;
    sortAsc: boolean;
  }) => {
    const rows = filteredData ?? [];
    const sortedItems = sortBy ? sortColumn(rows, sortBy, sortAsc) : rows;
    const startIndex = itemsPerPage * page - itemsPerPage;
    const endIndex = Math.min(rows.length, startIndex + itemsPerPage);

    const paginatedItems = sortedItems.slice(startIndex, endIndex);

    return {
      data: paginatedItems,
      totalItems: rows.length,
    };
  };

  const mapData = (externalConnection: NgbExternalConnectionResponseDto, index: number) => ({
    index: index,
    mhaName: externalConnection.mhaName,
    mhaExternalId: externalConnection.mhaExternalId,
    externalId: externalConnection.externalId,
    orgId: externalConnection.scopeType === 'organization' ? externalConnection.scopeId : null,
    divisionUuid: externalConnection.scopeType === 'division' ? externalConnection.scopeId : null,
    actions: externalConnection.scopeId ? displayActions(externalConnection) : null,
  });

  React.useEffect(() => {
    if (data) {
      setFilteredData(filterData(searchValue, data));
    }
  }, [searchValue, data]);

  return (
    <div className="sui-flex u-padLg u-sizeFull HockeyCanada" data-testid="hockey-canada">
      <div className="u-sizeFull sui-h-full">
        <div className="u-spaceBottomLg">
          <Breadcrumbs
            breadcrumbs={[
              <Link to="/">All Tools</Link>,
              <Link className="u-colorNeutral8" to="/hockey-canada">
                Hockey Canada Admin
              </Link>,
            ]}
          />
        </div>

        <NewConnectionForm />

        <Field
          name='searchValue'
          type='input'
          style={{ width: '100%' }}
          formFieldProps={{
            leftIcon: <Icon className="Icon" name="search" />,
            inputProps: {
              placeholder: 'Search by MHA Name, MHA External ID, HCR3 Org ID, TSB Org ID, or Persistent UUID',
              type: 'text',
              onChange: handleSearchValue,
              value: searchValue,
              'data-testid': 'hockeyCanadaSearchValueInput',
            },
          }}
        />

        <small>Find a Hockey Canada's connection.</small>

        <div className="hockey_canada--table_container">
          <PaginatedTable
            isLoading={processing}
            defaultItemsPerPage={itemsPerPage}
            noResultsText={'No connections found'}
            loadData={loadData}
            mapDataToRow={mapData}
            columns={columns}
            hideRowsSelect
            paginationPlacement={Placement.Bottom}
            reloadDependency={filteredData}
          />
        </div>
      </div>

      <Modal
        show={isDeleteModalOpen}
        heading='Delete Connection'
        showClose
        allowOverlayClose
        style={{
          padding: '20px',
        }}
        closeFn={closeModal}
      >
        <p>Are you sure you want to delete this connection?</p>
        {selectedConnection && (
          <ul>
            {
              selectedConnection.mhaName && (
                <li>
                  <strong>MHA Name:</strong> {selectedConnection.mhaName}
                </li>
              )
            }
            {
              selectedConnection.mhaExternalId && (
                <li>
                  <strong>MHA External ID:</strong> {selectedConnection.mhaExternalId}
                </li>
              )
            }
            <li>
              <strong>HCR3 Org ID:</strong> {selectedConnection.externalId}
            </li>
            <li>
              <strong>{selectedConnection.scopeType === 'organization' ? 'TSB Org ID' : 'Persistent UUID'}:</strong> {selectedConnection.scopeId}
            </li>
          </ul>
        )}
        <div className='modal-actions'>
          <Button onClick={onDelete} color='warning' mods='u-spaceTopMd'>
            Confirm
          </Button>
          <Button onClick={closeModal} mods='u-spaceTopMd'>
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
};
