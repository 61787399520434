import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NgbIntegrationsService, NgbExternalConnectionResponseDto, ExternalType, ScopeType } from 'core/api';
import { AsyncState } from 'core/types';
import { asyncFulfilledReducer, asyncPendingReducer, handleErrors, useAppSelector } from 'state/hooks';

const HOCKEY_CANADA_INTEGRATION_ID = 3;

interface HockeyCanadaAsyncState<T> extends AsyncState<T> {
  lastAction?: {
    action: 'update' | 'create';
    at: string;
  }
}

const initialState: HockeyCanadaAsyncState<NgbExternalConnectionResponseDto[]> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
  lastAction: undefined,
};

export const loadExternalConnections = createAsyncThunk('hockeyCanada/loadExternalConnections', async () => {
  const response = await handleErrors(() =>
    NgbIntegrationsService.listNgbExternalConnections(3)
  );

  return {
    data: response.data?.ngbExternalConnections as NgbExternalConnectionResponseDto[],
    error: response.error,
    errors: response.errors,
  };
});

export type CreateExternalConnectionPayload =
  | {
    hcr3OrgId: string;
    tsbOrgId: string;
    persistentUuid?: never;
  }
  | {
    hcr3OrgId: string;
    tsbOrgId?: never;
    persistentUuid: string;
  };


export const createExternalConnection = createAsyncThunk('hockeyCanada/createExternalConnection', async ({ hcr3OrgId, tsbOrgId, persistentUuid }: CreateExternalConnectionPayload, { rejectWithValue }) => {
  const response = await handleErrors(() =>
    NgbIntegrationsService.createNgbExternalConnection(HOCKEY_CANADA_INTEGRATION_ID, {
      externalType: ExternalType.HCR_ORGANIZATION,
      externalId: hcr3OrgId,
      scopeId: tsbOrgId ?? persistentUuid,
      scopeType: tsbOrgId ? ScopeType.ORGANIZATION : ScopeType.DIVISION,
    })
  );

  if (response.error || response.errors.length > 0) {
    return rejectWithValue(response.errors || response.error);
  }

  return {
    data: response.data as NgbExternalConnectionResponseDto,
    error: response.error,
    errors: response.errors,
  };
});


export const deleteExternalConnection = createAsyncThunk('hockeyCanada/deleteExternalConnection', async (connectionId: string, { rejectWithValue }) => {
  const response = await handleErrors(() =>
    NgbIntegrationsService.deleteNgbExternalConnection(connectionId)
  );

  if (response.error || response.errors.length > 0) {
    return rejectWithValue(response.errors || response.error);
  }

  return {
    data: { connectionId },
    error: response.error,
    errors: response.errors,
  };
});

export const slice = createSlice({
  name: 'hockeyCanada',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadExternalConnections.pending, asyncPendingReducer);
    builder.addCase(loadExternalConnections.fulfilled, (state, action) => asyncFulfilledReducer(state, action));
    builder.addCase(createExternalConnection.pending, asyncPendingReducer);
    builder.addCase(createExternalConnection.fulfilled, (state, action) => {
      const existingConnection = state.data?.find((connection) => connection.scopeId === action.payload.data?.scopeId)
        ?? state.data?.find((connection) => connection.externalId === action.payload.data?.externalId && !connection.scopeId);

      const actionAt = new Date().toString();

      if (existingConnection) {
        existingConnection.externalId = action.payload.data?.externalId;
        existingConnection.scopeId = action.payload.data?.scopeId;
        existingConnection.scopeType = action.payload.data?.scopeType;
        state.lastAction = {
          action: 'update',
          at: actionAt,
        };
      } else if (action.payload.data) {
        state.data = state.data
          ? [...state.data, action.payload.data].sort((a, b) => {
            // The ones with `id` should come first
            if (a.id !== null && b.id === null) return -1;
            if (a.id === null && b.id !== null) return 1;
            if (a.id !== null && b.id !== null) return a.id.localeCompare(b.id);

            // If none of them have `id`, sort by `mhaName`
            if (a.mhaName !== undefined && b.mhaName !== undefined) {
              return a.mhaName?.localeCompare(b.mhaName);
            }
            if (a.mhaName === undefined && b.mhaName !== undefined) return 1;
            if (a.mhaName !== undefined && b.mhaName === undefined) return -1;

            return 0;
          })
          : [action.payload.data]; state.lastAction = {
            action: 'create',
            at: actionAt,
          };
      }

      state.error = action.payload.error;
      state.errors = action.payload.errors;
      state.processing = false;
    });
    builder.addCase(createExternalConnection.rejected, (state, action) => {
      state.error = true;
      state.errors = ['An unexpected error occurred.'];
      state.processing = false;
    });
    builder.addCase(deleteExternalConnection.pending, asyncPendingReducer);
    builder.addCase(deleteExternalConnection.fulfilled, (state, action) => {
      state.data = state.data?.filter((connection) => connection.id !== action.payload.data.connectionId) ?? [];
      state.error = action.payload.error;
      state.errors = action.payload.errors;
      state.processing = false;
    });
  },
});

export const useHockeyCanadaStateSelector = () => useAppSelector((state) => state.hockeyCanada);
export const useHockeyCanadaSelector = () => useAppSelector((state) => state.hockeyCanada.data);

export default slice.reducer;
