import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


import { ToastProvider } from '@teamsnap/snap-ui';
import { Cell, Grid, Loader } from '@teamsnap/teamsnap-ui';

import { useAppDispatch } from 'state/hooks';
import { loadRolesForUser } from 'state/roles/roleSlice';
import { loadUser, useUserStateSelector } from 'state/user/userSlice';
import { Header } from '../components/shared';
import { ORGANIZATION_URL } from '../core/constants';
import { Billing } from './Billing';
import { BulkUpdatePage } from './BulkUpdate';
import { ClearContentfulCachePage } from './ClearContentfulCache';
import { DevActionsPage } from './DevActions';
import { Home } from './Home';
import NotFound from './NotFound';
import { Organizations } from './Organizations';
import { Registrations } from './Registrations';
import { HockeyCanada } from './HockeyCanada';
import { SecurityUsers } from './Security/SecurityUsers';
import { TeamSnapPlus } from './Subscriptions';

const LoadingPage = () => (
  <Grid isWithGutter>
    <Cell mods="u-flex u-flexJustifyCenter u-spaceBottomMd">
      <Loader type="spin" text="Loading..." />
    </Cell>
  </Grid>
);

export const Root = () => {
  const dispatch = useAppDispatch();
  const userState = useUserStateSelector();

  useEffect(() => {
    dispatch(loadUser());
    dispatch(loadRolesForUser());
  }, [dispatch]);

  if (userState.processing) {
    return <LoadingPage />;
  }

  if (!userState.data?.isAdmin) {
    window.location.href = ORGANIZATION_URL;
    return null;
  }

  const user = userState.data;

  return (
    <div>
      <Header title="TeamSnap Admin" profileName={`${user?.firstName} ${user?.lastName}`} />
      <BrowserRouter>
        <ToastProvider />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/organizations" element={<Organizations />} />
          <Route path="/registrations" element={<Registrations />} />
          <Route path="/hockey-canada" element={<HockeyCanada />} />
          <Route path="/bulk-upgrade" element={<BulkUpdatePage />} />
          <Route path="/clear-contentful-cache" element={<ClearContentfulCachePage />} />
          <Route path="/subscriptions" element={<TeamSnapPlus />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/dev-actions" element={<DevActionsPage />} />
          <Route path="/security/users" element={<SecurityUsers />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
