/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateNgbExternalConnectionRequest } from '../models/CreateNgbExternalConnectionRequest';
import type { GetNgbExternalConnectionsResponse } from '../models/GetNgbExternalConnectionsResponse';
import type { NgbExternalConnectionResponseDto } from '../models/NgbExternalConnectionResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NgbIntegrationsService {

    /**
     * @param ngbIntegrationId
     * @param scopeId
     * @param scopeType
     * @returns GetNgbExternalConnectionsResponse OK
     * @throws ApiError
     */
    public static listNgbExternalConnections(
        ngbIntegrationId: number,
        scopeId?: string,
        scopeType?: string,
    ): CancelablePromise<GetNgbExternalConnectionsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ngb-integrations/{ngbIntegrationID}/ngb-external-connections',
            path: {
                'ngbIntegrationID': ngbIntegrationId,
            },
            query: {
                'scopeId': scopeId,
                'scopeType': scopeType,
            },
        });
    }

    /**
     * @param ngbIntegrationId
     * @param requestBody
     * @returns NgbExternalConnectionResponseDto OK
     * @throws ApiError
     */
    public static createNgbExternalConnection(
        ngbIntegrationId: number,
        requestBody: CreateNgbExternalConnectionRequest,
    ): CancelablePromise<NgbExternalConnectionResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ngb-integrations/{ngbIntegrationID}/ngb-external-connections',
            path: {
                'ngbIntegrationID': ngbIntegrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param externalConnectionId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteNgbExternalConnection(
        externalConnectionId: string,
    ): CancelablePromise<{
        ok: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ngb-integrations/ngb-external-connections/{externalConnectionID}',
            path: {
                'externalConnectionID': externalConnectionId,
            },
        });
    }

}
