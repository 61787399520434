import * as React from 'react';
import './hockey_canada.scss';
import { Button } from '@teamsnap/teamsnap-ui';
import { useAppDispatch } from 'state/hooks';
import { createExternalConnection, useHockeyCanadaStateSelector } from 'state/hockeyCanada/hockeyCanadaSlice';

export const NewConnectionForm = () => {
    const [successMessage, setSuccessMessage] = React.useState<string | null>(null);

    const [formData, setFormData] = React.useState({
        tsbOrgId: '',
        hcr3OrgId: '',
        persistentUuid: '',
    });

    const [errors, setErrors] = React.useState({
        hcr3OrgId: '',
        tsbOrPersistentUuid: '',
    });

    const [submitError, setSubmitError] = React.useState<string | null>(null);

    const dispatch = useAppDispatch();

    const { lastAction } = useHockeyCanadaStateSelector();

    const saveData = async () => {
        const resultAction = await dispatch(
            createExternalConnection(
                formData.tsbOrgId
                    ? {
                        hcr3OrgId: formData.hcr3OrgId,
                        tsbOrgId: formData.tsbOrgId,
                    }
                    : {
                        hcr3OrgId: formData.hcr3OrgId,
                        persistentUuid: formData.persistentUuid,
                    }
            )
        );

        if (createExternalConnection.rejected.match(resultAction)) {
            setSubmitError('Failed to create the external connection. Please try again.');
        } else {
            setSubmitError(null);
        }
    };

    React.useEffect(() => {
        if (!lastAction) return;

        setSuccessMessage(lastAction.action === 'update' ? 'Connection updated successfully.' : 'Connection created successfully.');
        const timeout = setTimeout(() => setSuccessMessage(null), 5000);

        return () => clearTimeout(timeout);
    }, [lastAction]);

    const handleInputChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [field]: e.target.value,
        });
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = { hcr3OrgId: '', tsbOrPersistentUuid: '' };

        if (!formData.hcr3OrgId.trim()) {
            newErrors.hcr3OrgId = 'HCR3 Org ID is required.';
            isValid = false;
        }

        if (!formData.tsbOrgId.trim() && !formData.persistentUuid.trim()) {
            newErrors.tsbOrPersistentUuid = 'Either TSB Org ID or Persistent UUID is required.';
            isValid = false;
        } else if (formData.tsbOrgId.trim() && formData.persistentUuid.trim()) {
            newErrors.tsbOrPersistentUuid = 'Only one of TSB Org ID or Persistent UUID can be filled.';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSave = async () => {
        if (!validateForm()) return;

        await saveData();

        setFormData({
            tsbOrgId: '',
            hcr3OrgId: '',
            persistentUuid: '',
        });

        setErrors({
            hcr3OrgId: '',
            tsbOrPersistentUuid: '',
        });
    };

    return (
        <div className="hockey_canada--form-container">
            <h2 className="form-title">Hockey Canada Admin</h2>
            <p className="form-description">
                Enter the HCR3 Organization ID for the TSB Org or Persistent UUID.  Click Save to create or update connection. Click `Delete` for the HCR3 Org ID to stop using the specified TSB org or persistent uuid with Hockey Canada.
            </p>

            <form className="hockey_canada--form">
                <div className="inputs">
                    <div className="scope-id">
                        <div className="form-group">
                            <label htmlFor="tsbOrgId">TSB Org ID</label>
                            <input
                                id="tsbOrgId"
                                type="text"
                                placeholder="Ex: 2435"
                                value={formData.tsbOrgId}
                                onChange={handleInputChange('tsbOrgId')}
                                disabled={!!formData.persistentUuid}
                            />
                            {errors.tsbOrPersistentUuid && (
                                <p className="error-message">{errors.tsbOrPersistentUuid}</p>
                            )}
                        </div>
                        <div className="form-separator">OR</div>
                        <div className="form-group">
                            <label htmlFor="persistentUuid">Persistent UUID</label>
                            <input
                                id="persistentUuid"
                                type="text"
                                placeholder="Ex: 21331213213122"
                                value={formData.persistentUuid}
                                onChange={handleInputChange('persistentUuid')}
                                disabled={!!formData.tsbOrgId}
                            />
                            {errors.tsbOrPersistentUuid && (
                                <p className="error-message">{errors.tsbOrPersistentUuid}</p>
                            )}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="hcr3OrgId">HCR3 Org ID</label>
                        <input
                            id="hcr3OrgId"
                            type="text"
                            placeholder="Ex: 1eb5e695-9ab0-6b40-b93a-06ba8f7"
                            value={formData.hcr3OrgId}
                            onChange={handleInputChange('hcr3OrgId')}
                        />
                        {errors.hcr3OrgId && <p className="error-message">{errors.hcr3OrgId}</p>}
                    </div>
                </div>

                {submitError && <p className="error-message">{submitError}</p>}

                <Button type="primary" onClick={handleSave} className="save-button">
                    Save
                </Button>

                {successMessage && (
                    <div className="success-message u-spaceBottomMd">
                        <p>{successMessage}</p>
                    </div>
                )}

            </form>
        </div>
    );
};
